import { Link } from 'gatsby';
import { setLightness } from 'polished';
import * as React from 'react';
import styled, { css } from 'react-emotion';

import { colors } from '../styles/colors';
import { outer, inner } from '../styles/shared';
import config from '../website-config';

const SiteFooter = css`
  position: relative;
  padding-top: 20px;
  padding-bottom: 60px;
  color: #fff;
  background: ${setLightness('0.0015', colors.darkgrey)};
`;

const SiteFooterContent = css`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  color: rgba(255, 255, 255, 0.7);
  font-size: 1.3rem;
  a {
    color: rgba(255, 255, 255, 0.7);
  }
  a:hover {
    color: rgba(255, 255, 255, 1);
    text-decoration: none;
  }
  @media (max-width: 650px) {
    flex-direction: column;
  }
`;

const SiteFooterNav = styled.nav`
  display: flex;

  a {
    position: relative;
    margin-left: 20px;
  }

  a:before {
    content: '';
    position: absolute;
    top: 11px;
    left: -11px;
    display: block;
    width: 2px;
    height: 2px;
    background: #fff;
    border-radius: 100%;
  }

  a:first-of-type:before {
    display: none;
  }
  @media (max-width: 650px) {
    a:first-child {
      margin-left: 0;
    }
  }
`;

const Footer: React.SFC = () => {
  return (
    <footer className={`${outer} ${SiteFooter}`}>
      <div className={`${inner} ${SiteFooterContent}`}>
        <SiteFooterNav>
          If a conference isn't on here, you would like to sponsor this site, or to add your conference to
          the featured conferences list for the visitors of this site to see, please reach out to me at
          devmeetsconf@gmail.com.

          <Link to="/santabarbarasoftware">Made by Santa Barbara Software, {config.title} &copy; 2019</Link>
        </SiteFooterNav>
      </div>
    </footer>
  );
};

export default Footer;
