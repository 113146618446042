export interface WebsiteConfig {
  title: string;
  seoTitle: string;
  description: string;
  seoDescription: string;
  /**
   * blog full path, no ending slash!
   */
  siteUrl: string;
  facebookUrl?: string;
  twitterUrl?: string;
  twitterHandle?: string;
  /**
   * hide or show all email subscribe boxes
   */
  showSubscribe: boolean;
  /**
   * create a list on mailchimp and then create an embeddable signup form. this is the form action
   */
  mailchimpAction?: string;
  /**
   * this is the hidden input field name
   */
  mailchimpName?: string;
}

const config: WebsiteConfig = {
  title: 'Dev Meets Conf',
  description: 'Helping you find your next tech conference',
  seoTitle: 'Helping you find your next tech conference | DevMeetsConf.com',
  seoDescription: 'Choose the perfect developer, product, and UX conferences to attend in 2019 with our complete and up to date conference discovery tool. Filter by pricing, dates, locations, languages, and more.',
  siteUrl: 'https://www.devmeetsconf.com/',
  facebookUrl: 'https://www.facebook.com/Dev-Meets-Conf-580483709074672',
  twitterUrl: 'https://twitter.com/devmeetsconf',
  instagramUrl: 'https://www.instagram.com/devmeetsconf',
  twitterHandle: '@devmeetsconf',
  showSubscribe: false,
  // mailchimpAction: 'https://twitter.us19.list-manage.com/subscribe/post?u=a89b6987ac248c81b0b7f3a0f&amp;id=7d777b7d75',
  // mailchimpName: 'b_a89b6987ac248c81b0b7f3a0f_7d777b7d75',
};

export default config;
